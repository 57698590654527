import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const IndividualSuccessStory = ({
  header,
  text,
  learnMoreUrl,
  imageUrl,
  imageAlt,
}: {
  header: string;
  text: string;
  learnMoreUrl: string;
  imageUrl: string;
  imageAlt: string;
}) => (
  <>
    <Col>
      <Card bg="light" className="rounded-4 border-0">
        <Card.Img variant="top" src={imageUrl} alt={imageAlt} />
        <Card.Body>
          <Card.Title>{header}</Card.Title>
          <Card.Text>{text}</Card.Text>
          <Card.Link href={learnMoreUrl}>Full case study</Card.Link>
        </Card.Body>
      </Card>
    </Col>
  </>
);

const SuccessStories = () => (
  <>
    {/* <Container>
      <Container fluid className="mx-auto">
        <Container fluid="xxl" className="text-center">
          <h4>Success stories</h4>
          <p>
            Discover how we have helped organisations overcome their challenges and how our expertise can benefit you.
          </p>
        </Container>
      </Container>
      <Container fluid className="mx-auto">
        <Container fluid="lg" className="">
          <Row className="g-col-4">
            <IndividualSuccessStory
              header="Twigo.tv"
              text="This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer."
              learnMoreUrl=""
              imageUrl=""
              imageAlt=""
            />
            <IndividualSuccessStory
              header="Twigo.tv"
              text="This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer."
              learnMoreUrl=""
              imageUrl=""
              imageAlt=""
            />
            <IndividualSuccessStory
              header="Twigo.tv"
              text="This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer."
              learnMoreUrl=""
              imageUrl=""
              imageAlt=""
            />
          </Row>
        </Container>
      </Container>
    </Container> */}
  </>
);

export default SuccessStories;
