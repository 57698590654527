import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import heroSectionImg from "../../../assets/images/hero-section.png";
import Routes from "../../../components/Router/Routes";
import "./Hero.scss"

const Hero = () => (
  <>
    <Container fluid className="py-5 hero-gradient">
    {/* <Container fluid className="py-5" style={{background: "linear-gradient(to bottom right, rgba(24, 33, 69, 0.85), #182145)"}}> */}
      <Container fluid="xxl" className="pt-5">
        <Row className="align-items-center pt-5 row-gap-5">
          <Col lg="6" className="py-md-5">
            <h1 className="display-1 fw-bold text-body-emphasis lh-2 mb-3">
              Let's turn your <em className="text-secondary">vision</em> into{" "}
              <em className="text-secondary">reality</em>
            </h1>
            <p className="lead text-body-emphasis">
              Take the next steps with the right technology partner
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-center justify-content-lg-start">
              <Button
                variant="dark"
                size="lg"
                className="rounded-pill px-4 me-md-2"
                target="_blank"
                href={Routes.BOOK_CONSULTATION}
              >
                Book a free strategy call
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <Image
              src={heroSectionImg}
              className="d-block mx-auto"
              alt="Hero Section Image"
              fluid
              loading="lazy"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  </>
);

export default Hero;
