import Container from "react-bootstrap/Container";

const DevelopmentService = () => (
  <>
    <Container>
      <h1>Web Application Development</h1>
      <p>
        Are you confused if your idea can become a reality? Are you looking for
        someone with profound knowledge of building and scaling web
        applications? Guess what, you are at the right place! We've got your
        back.
      </p>
      <p>
        We use multiple elements of interactions and methods to draw the
        attention of the client to the services and products provided by your
        web app. We are a huge fan of using{" "}
        <strong>Python, TypeScript, JavaScript React, Django, and AWS</strong>{" "}
        to deliver more than what you expect. Not confined to technologies. We
        make sure to use what's best for your franchise. (Latest and prominent
        technologies).
      </p>
      <p>
        Our work doesn't end when the application is ready to become live. In
        fact, our responsibility doubles down there. Your beta customers are our
        first critics and we are happy to make changes to meet their
        expectations. We work with an agile mindset using robust ci/cd pipelines
        to deliver ideas to the user in a span of hours not days. More than our
        words, our work defines us.
      </p>
      <p>
        Although we are ready to take the end-to-end ownership from project
        inception to deployment and maintenance, we understand not every
        requirement is the same. You, alternatively, can onboard one of our
        experienced developers to work closely with your team and let the person
        bring the best practices of a corporate culture with the speed of a
        startup to your setup.
      </p>
    </Container>
  </>
);

export default DevelopmentService;
