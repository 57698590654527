import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import howItWorksSectionImg from "../../../assets/images/how-it-works.png";
import Routes from "../../../components/Router/Routes";

const HowItWorks = () => (
  <>
    <Container fluid className="bg-primary py-5">
      {/* <Container fluid className="py-5" style={{background: "linear-gradient(to bottom right, rgba(24, 33, 69, 0.85), #182145)"}}> */}
      <Container fluid="xxl" className="">
        <Row className="align-items-center row-gap-5">
          <Col lg="6" className="py-5">
            <h1 className="display-1 fw-bold text-body-emphasis lh-1 mb-3">
              How does it work?
            </h1>
            <p className="lead text-body-emphasis">
              We start with a 30-minute call to understand your requirements
              followed by a detailed and transparent proposal to form a
              strategic partnership. We understand that not every startup would
              need our services at this point, hence we won't push you around
              and can suggest cheaper alternatives to get started with.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-center justify-content-lg-start">
              <Button
                variant="dark"
                size="lg"
                className="rounded-pill px-4 me-md-2"
                target="_blank"
                href={Routes.BOOK_CONSULTATION}
              >
                Book a free 30-min strategy call
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <Image
              // TODO: Change me
              src={howItWorksSectionImg}
              className="d-block mx-auto"
              alt="Hero Section Image"
              fluid
              loading="lazy"
            />
          </Col>
        </Row>
      </Container>
    </Container>

    {/* <Container fluid className="bg-primary text-center text-light py-5 p-xxl-5">
      <Container fluid="xxl">
        <h4>How does it work?</h4>
        <p>
          We start with a 30-minute call to understand your requirements
          followed by a detailed and transparent proposal to form a strategic
          partnership. We understand that not every startup would need our
          services at this point, hence we won't push you around and can suggest
          cheaper alternatives to get started with.
        </p>
      </Container>
      <Image
        // TODO: Change me
        src={howItWorksSectionImg}
        className="d-block mx-auto"
        alt="Hero Section Image"
        fluid
        loading="lazy"
      />
    </Container> */}
  </>
);

export default HowItWorks;
