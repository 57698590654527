import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import logo from "../../assets/images/brand/logos/Original.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faSquareInstagram,
  faSquareXTwitter,
  faUpwork,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import Routes from "../Router/Routes";
import { faEnvelope, faSquarePhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => (
  <>
    <Container className="align-items-center text-center border-top mt-4 pt-4">
      <Container className="pt-3">
        <Link to={Routes.HOME}>
          <Image src={logo} className="" height="60" />
        </Link>
      </Container>
      {/* <Row xs="auto" className="pt-3 justify-content-center">
        <Nav>
          <Nav.Link href="#">Home</Nav.Link>
          <Nav.Link href="#">Link</Nav.Link>
          <Nav.Link href="#">Link</Nav.Link>
          <Nav.Link>Disabled</Nav.Link>
        </Nav>
      </Row> */}
      <Row xs="auto" className="pt-3 justify-content-center">
        <Link to={Routes.SOCIAL_LINKEDIN} target="_blank" className="link-dark"><FontAwesomeIcon icon={faLinkedin} size="2x" className="" /></Link>
        <Link to={Routes.SOCIAL_UPWORK} target="_blank" className="link-dark"><FontAwesomeIcon icon={faUpwork} size="2x" className="" /></Link>
        <Link to={Routes.SOCIAL_TWITTER} target="_blank" className="link-dark"><FontAwesomeIcon icon={faSquareXTwitter} size="2x" className="" /></Link>
        <Link to={Routes.SOCIAL_INSTAGRAM} target="_blank" className="link-dark"><FontAwesomeIcon icon={faSquareInstagram} size="2x" className="" /></Link>
        <Link to={Routes.MAILTO} className="link-dark"><FontAwesomeIcon icon={faEnvelope} size="2x" className="" /></Link>
        <Link to={Routes.CALL} target="_blank" className="link-dark"><FontAwesomeIcon icon={faSquarePhone} size="2x" className="" /></Link>
      </Row>

      <Container className="pt-3">
        <p className="text-body-secondary">
          WeWork, DLF Two Horizon Centre, Gurugram, Haryana, India - 122002
          <br />
          WeWork, 21 Soho Square, London, United Kingdom - W1D 3QP
          <br />
          <small>© 2024 Sonco Technologies. All rights reserved.</small>
        </p>
      </Container>
    </Container>
  </>
);

export default Footer;
