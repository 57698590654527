import "./App.scss";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import { Routes, Route, Outlet } from "react-router-dom";
import ConsultingCTOService from "./pages/Services/ConsultingCTOService/ConsultingCTOService";
import SoncoRoutes from "./components/Router/Routes";
import DesignService from "./pages/Services/DesignService/DesignService";
import DevelopmentService from "./pages/Services/DevelopmentService/DevelopmentService";
import DevOpsService from "./pages/Services/DevOpsService/DevOpsService";
import ScrumTeamAsAService from "./pages/Services/ScrumTeamAsAService/ScrumTeamAsAService";

function App() {
  return (
    <>
      <Routes>
        <Route
          path={SoncoRoutes.HOME}
          element={
            <>
              <Header />
              <Outlet />
              <Footer />
            </>
          }
        >
          <Route index element={<Home />} />
          <Route
            path={SoncoRoutes.SERVICE_CONSULTING_CTO}
            element={<ConsultingCTOService />}
          />
          <Route
            path={SoncoRoutes.SERVICE_DESIGN}
            element={<DesignService />}
          />
          <Route
            path={SoncoRoutes.SERVICE_DEVELOPMENT}
            element={<DevelopmentService />}
          />
          <Route
            path={SoncoRoutes.SERVICE_DEVOPS}
            element={<DevOpsService />}
          />
          <Route
            path={SoncoRoutes.SERVICE_SCRUM_TEAM_AS_A_SERVICE}
            element={<ScrumTeamAsAService />}
          />

          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
