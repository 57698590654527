import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import logo from "../../assets/images/brand/logos/White.svg";
import Routes from "../Router/Routes";

const Header = () => (
  <Navbar as="header" expand="lg" data-bs-theme="light" className="position-absolute w-100">
    <Container className="py-3">
      <Navbar.Brand href={Routes.HOME}>
        <Image src={logo} alt="Sonco Technologies Logo" height="40" className="d-md-none" />
        <Image src={logo} alt="Sonco Technologies Logo" height="40" className="d-none d-md-block" />
      </Navbar.Brand>
      <Nav>
        <Nav.Link target="_blank" href={Routes.BOOK_CONSULTATION}>
          <Button variant="dark" className="rounded-pill d-md-none">
            {/* TODO: Better name */}
            Book a free call
          </Button>
          <Button variant="dark" className="rounded-pill d-none d-md-block">
            Book a free 30-min strategy call
          </Button>
        </Nav.Link>
      </Nav>
    </Container>
  </Navbar>
);

export default Header;
