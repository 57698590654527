import Row from "react-bootstrap/Row";
import Services from "./Services/Services";
import SuccessStories from "./SuccessStories/SuccessStories";
import Founder from "./Founder/Founder";
import Testimonials from "./Testimonials/Testimonials";
import Hero from "./Hero/Hero";
import HowItWorks from "./HowItWorks/HowItWorks";

function Home() {
  return (
    <>
      {/* <Container fluid className="bg-primary"> */}
        <Row className="align-items-center">
          <Hero />
          <Services />
          <HowItWorks />
          <SuccessStories />
          <Testimonials />
          <Founder />
        </Row>
      {/* </Container> */}
    </>
  );
}

export default Home;
