import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeftAlt, faStar } from "@fortawesome/free-solid-svg-icons";

const IndividualTestimonial = ({
  header,
  designation,
  text,
}: {
  header: string;
  designation: string;
  text: string;
}) => (
  <>
    <Col className="">
      <Card bg="transparent" className="rounded-4 border-0 shadow h-100">
        <Card.Body>
          <Card.Text className="text-dark mb-0">
            <FontAwesomeIcon icon={faQuoteLeftAlt} className="text-dark" />
            <blockquote>{text}</blockquote>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="bg-transparent">
          <cite>
            <Card.Title className="">{header}</Card.Title>
            <Card.Subtitle className="">{designation}</Card.Subtitle>
          </cite>
          <FontAwesomeIcon icon={faStar} className="text-dark" />
          <FontAwesomeIcon icon={faStar} className="text-dark" />
          <FontAwesomeIcon icon={faStar} className="text-dark" />
          <FontAwesomeIcon icon={faStar} className="text-dark" />
          <FontAwesomeIcon icon={faStar} className="text-dark" />
        </Card.Footer>
      </Card>
    </Col>
  </>
);

const Testimonials = () => (
  <>
    <Container fluid className="text-dark text-center py-5 p-xxl-5">
      <Container fluid="xxl">
        <h4>What do our clients say?</h4>
        <p>
          We drive noteworthy business value through technology and our 100% job
          success score and being top-rated on Upwork justifies this.
        </p>
      </Container>
      <Container fluid className="mx-auto">
        <Container fluid="lg">
          <CardGroup>
            <Row xs={1} md={2} xxl={4} className="row-gap-3">
              <IndividualTestimonial
                header="Oren Keinan"
                designation="CTO, Twigo.tv"
                text="Swakeert is very talented and we were
                    lucky to work with him. He has vast
                    knowledge and experience in different
                    fields of AWS and Python that we used to
                    solve difficult problems and develop
                    some cool and not trivial features. He is a
                    very nice person, easy to communicate
                    with, and a pleasure to work with. I highly
                    recommend hiring him. I hope I will have
                    the opportunity in the future to work with
                    him again."
              />
              <IndividualTestimonial
                header="Brian Garr"
                designation="Chief Product Office, PureTalk.AI"
                text="Did an awesome job and was so great to work with over zoom to help implement on our secure servers."
              />
              <IndividualTestimonial
                header=""
                designation=""
                text="a real problem solver, great communication, pleasure to work with. highly recommended"
              />
              <IndividualTestimonial
                header="Naman Mahendroo"
                designation="Principal Solutions Architect, Appboxer"
                text="Executed the work very smoothly, and have always been there for the support. Would recommend working with him."
              />
            </Row>
          </CardGroup>
        </Container>
      </Container>
    </Container>
  </>
);

export default Testimonials;
