import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import SwakeertJainImg from "../../../assets/images/Swakeert-Jain.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import Routes from "../../../components/Router/Routes";

const Founder = () => (
  <>
    <Container fluid className="bg-dark text-center text-light p-5">
      <Container fluid="xxl" className="p-5">
        <FontAwesomeIcon
          icon={faQuoteLeft}
          size="3x"
          className="text-secondary pb-2"
        />
        <blockquote className="blockquote">
          At Sonco Technologies, we believe that integrity, creativity and
          collaboration are the key ingredients to delivering exceptional
          results for our clients.
        </blockquote>
        <Image
          fluid
          roundedCircle
          height={150}
          width={150}
          src={SwakeertJainImg}
          alt="Swakeert Jain, Founder of Sonco Technologies"
          className="mb-4"
        />
        <figcaption className="blockquote-footer">
          Swakeert Jain, Founder
        </figcaption>
      </Container>
      <Button
        variant="light"
        size="lg"
        target="_blank"
        href={Routes.BOOK_CONSULTATION}
        className="rounded-pill px-4 me-md-2"
      >
        Book a free 30-min strategy call
      </Button>
    </Container>
  </>
);

export default Founder;
