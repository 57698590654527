const enum Routes {
  HOME = "/",
  BOOK_CONSULTATION = "https://calendly.com/swakeert/30min",
  SERVICE_CONSULTING_CTO = "services/consulting-cto/",
  SERVICE_SCRUM_TEAM_AS_A_SERVICE = "services/scrum-team-as-a-service/",
  SERVICE_DESIGN = "services/design/",
  SERVICE_DEVELOPMENT = "services/development/",
  SERVICE_DEVOPS = "services/devops-and-solutions-architect/",
  SOCIAL_LINKEDIN = "https://www.linkedin.com/company/sonco-technologies",
  SOCIAL_UPWORK = "https://www.upwork.com/agencies/1592518014294904832/",
  SOCIAL_TWITTER = "https://twitter.com/soncotech",
  SOCIAL_INSTAGRAM = "https://www.instagram.com/sonco.tech",
  MAILTO = "mailto:swakeert.jain@sonco.tech",
  CALL = "tel:+919999330803",
}
export default Routes;
