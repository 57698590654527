import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import heroSectionImg from "../../../assets/images/hero-section.gif";
import servicesSectionImg from "../../../assets/images/services-section.png";
import consultingCTOImg from "../../../assets/images/consulting-cto-service.png";
import Routes from "../../../components/Router/Routes";
import "./Services.scss";

const IndividualService = ({
  header,
  text,
  learnMoreUrl,
  imageUrl,
  imageAlt,
  reverse = false,
}: {
  header: string;
  text: string;
  learnMoreUrl: string;
  imageUrl: string;
  imageAlt: string;
  reverse?: boolean;
}) => (
  <>
    <Row
      className={`align-items-center m-1 p-1 ${
        reverse ? "flex-row-reverse ms-lg-n5 my-3" : "ms-lg-5"
      }`}
    >
      <Col lg="6" className="px-3 pt-3 shadow rounded">
        <h3 className="">{header}</h3>
        <p>
          {text}
          {/* <div className="justify-content-end text-end">
            <a
              className="link-primary link-offset-3 link-opacity-50-hover"
              href={learnMoreUrl}
            >
              + Learn More
            </a>
          </div> */}
        </p>
      </Col>
      {/* <Col lg="6">
        <Image
          src={imageUrl}
          width="240"
          className="d-block mx-auto"
          alt={imageAlt}
          fluid
          loading="lazy"
        />
      </Col> */}
    </Row>
  </>
);

const Services = () => (
  <>
    {/* TODO: Improve services and link to service explanation page
     */}
    <Container fluid className="mx-auto py-5 bg-light ">
      <Container fluid="sm" className="">
        <Row className="text-center">
          <p className="lead">
            With combined 15+ years of experience in tech and design, we believe
            in turning brilliant ideas into reality, helping early-stage
            startups to go from ideation to the execution stage, and driving
            noteworthy business value through technology.
          </p>
        </Row>
        <Row className="text-center">
          <Container fluid="xs">
            <Image
              // TODO: Change my bg color to match
              src={servicesSectionImg}
              width={400}
              alt="Next Step Image"
              fluid
              loading="lazy"
            />
          </Container>
        </Row>

        <Container fluid className="text-center pt-5 p-xxl-5">
          <h4>Our Services</h4>
        </Container>

        <IndividualService
          header="Consulting CTO"
          text="Bring onboard an experienced CTO to take
          ownership of mentoring the tech talent, recruiting, setting up interviews,
          hiring with a cultural mindset and making long-term architectural decisions."
          learnMoreUrl={Routes.SERVICE_CONSULTING_CTO}
          imageUrl={consultingCTOImg}
          imageAlt=""
        />

        <IndividualService
          header="Scrum Team as a Service"
          text="The hot new approach pioneered by Sonco Technologies,
          where you get a dedicated cross-functional team working solely on your project,
          delivering quality products with customer obsession."
          learnMoreUrl={Routes.SERVICE_SCRUM_TEAM_AS_A_SERVICE}
          imageUrl={heroSectionImg}
          imageAlt=""
          reverse
        />

        <IndividualService
          header="Web and Mobile Design"
          text="We narrate a unique story through every design. Hence, we build
          minimalistic and futuristic applications with 100% achievable and
          technically feasible wireframes and designs."
          learnMoreUrl={Routes.SERVICE_DESIGN}
          imageUrl={heroSectionImg}
          imageAlt=""
        />

        <IndividualService
          header="Web Application Development"
          text="Turn your realistic ideas into user-friendly web apps. Let us
          handle your web app with end-to-end ownership from project
          inception to deployment and maintenance so that you can focus on
          other aspects of the business."
          learnMoreUrl={Routes.SERVICE_DEVELOPMENT}
          imageUrl={heroSectionImg}
          imageAlt=""
          reverse
        />

        <IndividualService
          header="DevOps and Solution Architecture"
          text="Scale your application with users and not with codebase while
          spending a minimal amount at each step. From creating high-level
          architectures to writing detailed deployment scripts, we put the
          Dev approach to DevOps."
          learnMoreUrl={Routes.SERVICE_DEVOPS}
          imageUrl={heroSectionImg}
          imageAlt=""
        />
      </Container>
    </Container>
  </>
);

export default Services;
